import './App.css';
import React from 'react';

function sendAuthRequestToRoger356() {
    const message = JSON.stringify({
        target: 'roger365.iframe.message',
        url: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=ad5aa5a9-fd88-4956-b097-1816412a0bbc&response_type=code+id_token&redirect_uri=https%3a%2f%2fadmin-development.roger365.io%2fContactCenterApp%2fiframeauth&response_mode=fragment&scope=openid&state=4&nonce=a86bb1ee9e3b484fa5bc1888368ece4d_20230601125332',
    });

    window.parent.postMessage(message, '*');
}

function App() {
    const [status, setStatus] = React.useState('INIT');
    const [token, setToken] = React.useState('TOKEN');

    const handleMessageReceived = (event) => {
        if (event.data?.target === 'roger365.iframe.auth.token') {
            console.log('Token received');
            console.log('Token: ', event.data.token);
            setStatus('TOKEN_RECEIVED');
            setToken(event.data.token);
        }
    };

    React.useEffect(() => {
        sendAuthRequestToRoger356();
    }, []);

    React.useEffect(() => {
        window.addEventListener('message', handleMessageReceived);
        setStatus('TOKEN_REQUESTED');

        // cleanup this component
        return () => {
            window.removeEventListener('message', handleMessageReceived);
        };
    }, []);

    let content = <span></span>;

    if (status === 'INIT') {
        content = <span>Initializing application</span>;
    } else if (status === 'TOKEN_REQUESTED') {
        content = <span>Token Requested</span>;
    } else if (status === 'TOKEN_RECEIVED') {
        content = <span>Token Received: {token}</span>;
    } else {
        content = <span>Unknown status</span>;
    }

    return (
        <div className='App'>
            <header className='App-header'>
                <h1>Test App Roger 365 Integration</h1>
            </header>
            <div>{content}</div>
        </div>
    );
}

export default App;
